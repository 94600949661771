import React from "react"
import CaseStudies2 from "./case-studies";

const Resources = () => {
    return (
        <CaseStudies2 />
    )
}

export default Resources;

