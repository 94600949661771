import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import "../../../styles/case-studies.scss"
//import linkedIn from '../../../assets/linkedIn.svg'
import facebook from "../../../assets/images/facebook.png"
import twitter from "../../../assets/images/twitter.png"
import linkedIn from "../../../assets/images/linkedin-logo.png"
import industrialImg from "../../../assets/images/industry-background.jpg"
import jsplImg from "../../../assets/images/jspl-cover.png"
import stanzenImg from "../../../assets/images/stanzen-cover.png"


const caseStudyData =[
    {
        image:jsplImg,
        title:'Jindal Steel & Power Limited used our Lucrys Analytics Platform to connect their machines, track and improve the deviations in the manufacturing process on real time basis.',
        content:'JSPL is an industrial powerhouse with a dominant presence in steel, power, mining and infrastructure sectors. Part of the US $ 22 billion OP Jindal Group, the Company is continuously scaling its capacity utilisations and efficiencies to capture opportunities for Building A Nation of Our Dreams.',
        caseLink:'/resources/case-studies/jspl',
        fbLink:'https://www.facebook.com/sharer/sharer.php?u=https://machstatz.com/jspl',
        linkedinLink:'https://www.linkedin.com/shareArticle?mini=true&url=https://machstatz.com/jspl&title=Jindal Steel & Power Limited uses our Lucrys analytics&source=JSPL with Machstatz',
        twitterLink:'https://twitter.com/intent/tweet?text=https://machstatz.com/jspl'
    },
    {
        image:industrialImg,
        title:'A Chemical Manufacturing Plant successfully predicted and prevented 12 hours of machine downtime using pattern recognition and AI (a 10x ROI!)',
        content:'This plant is the only company with an integrated Titanium Dioxide facility having mining, mineral separation, synthetic rutile and pigment-production plants. Their products are employed as essential components in cosmetics, fashion, medical and paints industry.',
        caseLink:'/resources/case-studies/kmml',
        fbLink:'https://www.facebook.com/sharer/sharer.php?u=https://machstatz.com/kmml',
        linkedinLink:'https://www.linkedin.com/shareArticle?mini=true&url=https://machstatz.com/kmml&title=Chemical manufacturing Plant&source=Chemical manufacturing plant with Machstatz',
        twitterLink:'https://twitter.com/intent/tweet?text=https://machstatz.com/kmml'
    },
    {
        image:stanzenImg,
        title:'Stanzen Engineering increased the operational efficiency of their entire plan by 30% using our Industrial IoT Gateways and Lucrys Analytics.',
        content:'Stanzen Engineering is one of the leading players in the automotive stamping and related solutions. In their three decades of operating in the industry, they have satisfactorily fulfilled hundreds of projects involving body, chassis, assembly and safety standards. Their range of solutions includes pressing, welding, assembling and tooling.',
        caseLink:'/resources/case-studies/stanzen',
        fbLink:'https://www.facebook.com/sharer/sharer.php?u=https://machstatz.com/stanzen',
        linkedinLink:'https://www.linkedin.com/shareArticle?mini=true&url=https://machstatz.com/stanzen&title=Stanzen&source=stanzen with Machstatz',
        twitterLink:'https://twitter.com/intent/tweet?text=https://machstatz.com/stanzen'
    },
]

const CaseStudies2 = () => (
    <Layout>
        <SEO title="Case Studies" />
        <div className="row industry-banner text-center">
            <div className="col-12 white">
                <h5>Chemical Process Industry</h5>
                <h1 className="pt-2">Prevent repetitive shutdowns.</h1>
                {/* <p className="font-14 pt-2">November 6, 2019 - John Wick</p> */}
            </div>
        </div>
        <br />
        <div className="container">
            
            {caseStudyData.map(item=>{
                return(
                    <div className="row m-0 pl-lg-0 pl-md-0 cases-card">
                <div className="col-lg-7 p-0 col-md-12">
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <Link to={item.caseLink}>
                                <img src={item.image} className="case-study-img"
                                    width="400" height="400" />
                            </Link>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <Link to={item.caseLink}>
                                <h2 className="blog-head">{item.title}</h2>
                            </Link>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <p className="case-study-read-more pt-3 pl-0">
                                {item.content}
                        </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2 mt-lg-3 flex-wrap share-wrapper">
                        <div className="order-md-3 share-request-text">
                            <p className="pl-md-2 m-0 text-center">Don't forget to share this story!</p>
                        </div>
                        <div className="share-icons mt-2 mt-lg-0 mt-md-0 pr-md-2 text-center" style={{flex:'none'}}>
                            <a className="share-icon" target="_blank" href={item.fbLink} >
                                <img className="icons" src={facebook}></img>
                            </a>
                            <a className="share-icon" target="_blank" href={item.linkedinLink} >
                                <img className="icons" src={linkedIn}></img>
                            </a>
                            <a className="share-icon" target="_blank" href={item.twitterLink}>
                                <img className="icons" src={twitter}></img>
                            </a>
                        </div>
                        <div className="share-dashes d-none d-md-block">
                        </div>
                    </div>
                </div>
                <br />
            </div>
                )
            })}
        </div>
    </Layout >
)

export default CaseStudies2


